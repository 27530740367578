import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
const SvgColor = forwardRef(({ src, sx, ...other }, ref) => (_jsx(Box, { component: "span", className: "svg-color", ref: ref, sx: {
        width: 24,
        height: 24,
        display: 'inline-block',
        bgcolor: 'currentColor',
        mask: `url(${src}) no-repeat center / contain`,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
    }, ...other })));
export default SvgColor;
